import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Menu = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  return (
    <MenuContainer>
      <TitleWrapper>
        <StyledLine />
        <Title>Our Menu</Title>
        <StyledLine />
      </TitleWrapper>

      <MenuRow>
        {[1, 2].map((menuNumber) => (
          <MenuColumn key={menuNumber}>
            <MenuImage
              src={`/menus/menu${menuNumber}.webp`}
              alt={`Menu ${menuNumber}`}
            />
          </MenuColumn>
        ))}
      </MenuRow>
    </MenuContainer>
  );
};

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
  animation: ${fadeIn} 1s ease forwards;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #000000;
  text-align: center;
  position: relative;
  margin: 2rem 0;
  animation: ${slideIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 50%;
  width: 170%;
  transform: translateX(-50%);
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  padding: 0 2rem; /* Horizontal padding for equal margins */
  box-sizing: border-box; /* Include padding in width calculation */
  width: 100%;
`;

const MenuRow = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute space evenly */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  width: 100%;
  gap: 1rem; /* Space between images */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack images vertically on smaller screens */
    align-items: center; /* Center images vertically */
    gap: 1.5rem; /* Space between stacked images */
  }
`;

const MenuColumn = styled.div`
  flex: 1;
  max-width: 48%;
  margin: 0;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #000000; /* Add a 2px black border */

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
    margin-bottom: 3rem;
  }
  margin-bottom: 3rem;
`;

const MenuImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export default Menu;
