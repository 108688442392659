import React, { useEffect, useRef } from 'react';
import styled from "styled-components";

const Reviews = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://reviews.beaver.codes/widget/web-google-reviews.js';
    script.async = true;

    // Append the script to the container
    containerRef.current?.appendChild(script);

    return () => {
      // Clean up the script element
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
    <Title>Reviews</Title>
    <div ref={containerRef} data-instance-id="cBGpPWeqFerpqOlMo2xA" />
    </div>
  );
};

export default Reviews;

const Title = styled.h2`
  font-size: 40px;
  margin-bottom: 1.5rem;
  text-align: center; /* Center the title */
`;
